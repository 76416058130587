:root {
  /* colors */
  --dark: #2C3639;
  --green: #87CEC6;
  --grey: #2C3333;
  --creme: #FBF2CF;
}
.minHeightMaxWidth {
  min-height: 90vh;
  width: 100vw;
}
.marginAuto {margin: auto;}
.centerHorizontally {
  display: flex;
  justify-content: center;
}
.centerVertically {
  display: flex;
  align-items: center;
}
.flexVertically {
  display: flex;
  flex-direction: column;
}
.flexHorizontally {
  display: flex;
  flex-direction: row;
}
.gap {
  display: flex;
  gap: 0.5rem;
}
.box {
  background-image: url(./imgs/white-transparent.png);
  background-size: cover;
  width: fit-content;
  padding: 2rem;
  margin: 1rem;
  border-radius: 0.25rem;
}
