:root {--red: #EB455F;}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*///////////////////////////////////navbar*/
nav {
  position: fixed;
  height: 10vh;
  width: 100vw;
}
nav img{
  position: fixed;
  margin-top: 0.5rem;
  right: 0;
  top: 0;
} 
nav h2 {
  color: black;
  font-family: 'Dosis', sans-serif;
}
#mobileMenuIcon {
  font-size: 2.5rem;
  margin-top: 0.5rem;
  color: var(--red);
}
main {
  width: 100vw;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer {
  height: 10vh;
  width: 100vw;
}
footer p {text-align: center;}
.bg {
  background-image: url("./imgs/IMG_9410_1920x1200.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
/*///////////////////////////////////texts*/
h1, h2, h3, h5, h6, p {
  margin: 0;
  padding: 0;
}
h1 {text-align: center;}
#dashboard p {text-align: center;}
#space {height: 1rem;}

/*///////////////////////////////////////////////attendance*/
#attendanceRecords li {list-style: none;}

/*////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////////////media queries*/
/*///////////////////////////////////////////////desktop-view*/
@media only screen and (min-width: 768px) {
  nav img {width: 15vw;}
  #break {height: 10vh;}
}

/*////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////////////media queries*/
/*///////////////////////////////////////////////mobile-view*/
@media only screen and (max-width: 767px) {
  nav img {width: 25vw;}
  #break {height: 5vh;}
}
